<template>
  <div>
    <b-modal
      ref="modalComingSoon"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      id="modal-coming-soon"
       v-model="showModal"
    >
      <div class="modal-header">
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('modal-coming-soon')"
        >
          ×
        </button>
      </div>
      <div class="text-center">
        <img
          src="@/assets/images/Coming-soon-Landing-Page.png"
          alt="comingsoon"
          class="mb-3 w-75"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      showModal: false
    };
  },

  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    }
  }
};
</script>

<style>

</style>

<style scoped> 
::v-deep .modal-content {
  border-radius: 30px !important;
}

.modal-header {
  border: 0;
  padding: 0 10px;
}
.close {
  font-size: 2.3rem;
}
</style>